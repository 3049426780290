import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import { useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";

const ExportMailingListModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<string>("");

  // Firebase callable function
  const exportMailingList = httpsCallable(functions, "exportMailingList");

  const handleExport = async () => {
    try {
      setIsLoading(true);
      setProgress("Gebruikerslijst ophalen...");

      // Initialize variables for pagination
      const perPage = 100; // Number of users to fetch per page
      let currentPage = 1;
      let hasMoreUsers = true;
      let allUsers: any[] = [];

      // Keep fetching until there are no more users
      while (hasMoreUsers) {
        setProgress(`Gebruikerslijst ophalen (pagina ${currentPage})...`);

        // Call the Firebase function with pagination parameters
        const result = await exportMailingList({
          perPage,
          page: currentPage
        });

        const data = result.data as any[];

        // If no users returned or empty array, break out of the loop
        if (!data || data.length === 0) {
          hasMoreUsers = false;
          break;
        }

        // Add the users to our collection
        allUsers = allUsers.concat(data);

        // If we received fewer users than perPage, we've got all of them
        if (data.length < perPage) {
          hasMoreUsers = false;
        } else {
          // Move to the next page
          currentPage++;
        }
      }

      if (allUsers.length > 0) {
        setProgress("CSV bestand genereren...");

        // Generate CSV content
        const csvContent = generateCsv(allUsers);

        // Trigger download
        downloadCsv(csvContent, "mailing_list.csv");

        setAlert({
          message: `${allUsers.length} gebruikers geëxporteerd naar mailing_list.csv`,
          type: "success"
        });
      } else {
        setAlert({ message: "Geen gebruikers gevonden", type: "warning" });
      }
    } catch (error) {
      console.error("Error exporting mailing list:", error);
      setAlert({
        message: "Er is een fout opgetreden bij het exporteren van de mailinglijst",
        type: "error"
      });
    } finally {
      setIsLoading(false);
      setProgress("");
    }
  };

  const generateCsv = (users: any[]): string => {
    // Create CSV header
    let csvContent = "username,email\n";

    // Add each user as a row
    users.forEach(user => {
      const username = user.username || "";
      const email = user.email || "";

      // Escape commas and quotes in the fields if needed
      const escapedUsername = username.includes(",") ? `"${username}"` : username;
      const escapedEmail = email.includes(",") ? `"${email}"` : email;

      csvContent += `${escapedUsername},${escapedEmail}\n`;
    });

    return csvContent;
  };

  const downloadCsv = (csvContent: string, filename: string) => {
    // Create a blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    // Add to document, trigger download, and clean up
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose} disabled={isLoading}>ANNULEER</Button>
          <Button flatButton onClick={handleExport} disabled={isLoading}>
            {isLoading ? progress || "BEZIG MET EXPORTEREN..." : "DOWNLOAD MAILINGLIJST"}
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <h2>Mailinglijst Exporteren</h2>
      <p>Klik op de download knop om een CSV bestand van alle gebruikers te exporteren.</p>
      <p>De gebruikerslijst bevat de gebruikersnaam en e-mailadres van alle gebruikers.</p>

      {isLoading && (
        <div className="progress-container">
          <p className="progress-text">{progress}</p>
        </div>
      )}
    </Modal>
  );
};

export default ExportMailingListModal;